<template>
  <div id="myNav" class="overlay">
    <a @click="$emit('closeLinks')" class="closebtn">&times;</a>
    <div class="overlay-content">
      <div class="text-center text-light">
        <h1 class="mb-1">Share</h1>
        <p class="mb-2">
          {{ title }}
        </p>
        <h6>TOP SERVICES</h6>
      </div>
      <div
        class="links-container w-50 d-flex justify-content-center flex-wrap mx-auto my-4"
      >
        <div class="each-link">
          <ShareNetwork
            network="telegram"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <circle cx="256" cy="256" fill="#0088CC" id="ellipse" r="256" />
                <path
                  d="M246.4,332.1c-12.3,11.9-24.4,23.7-36.5,35.5c-4.2,4.1-8.9,6.4-15,6.1c-4.1-0.2-6.4-2-7.7-5.9  c-9.2-28.6-18.6-57.2-27.8-85.9c-0.9-2.8-2.2-4.1-5-5c-21.7-6.6-43.5-13.4-65.1-20.3c-3.3-1.1-6.7-2.4-9.6-4.4  c-4.5-3-5.1-7.9-1.1-11.5c3.7-3.3,8.1-6.1,12.7-7.9c26.6-10.5,53.3-20.7,80-31c67.7-26.1,135.4-52.3,203.1-78.4  c12.9-5,22.8,2,21.4,16c-0.9,8.9-3.2,17.7-5,26.5c-14.7,69.4-29.4,138.9-44.2,208.3c-3.5,16.5-15.1,20.8-28.6,10.8  c-22.7-16.7-45.4-33.5-68.1-50.3C248.8,333.8,247.7,333,246.4,332.1z M195.4,353.2c0.3-0.1,0.5-0.1,0.8-0.2c0.1-0.7,0.3-1.3,0.4-1.9  c1.5-15.7,3-31.5,4.3-47.2c0.3-3.5,1.5-6,4.1-8.4c20.9-18.7,41.8-37.6,62.6-56.4c23.1-20.8,46.2-41.6,69.2-62.5c1.4-1.3,2-3.5,3-5.3  c-2.2-0.2-4.5-1.1-6.5-0.6c-2.7,0.7-5.2,2.3-7.6,3.8c-50.9,32.1-101.9,64.2-152.8,96.3c-2.9,1.8-3.4,3.3-2.3,6.5  c3.8,10.8,7.2,21.7,10.7,32.6C186,324.3,190.7,338.8,195.4,353.2z"
                  fill="#FFFFFF"
                  id="logo"
                />
              </svg>
            </div>
            <p class="mt-1">Telegram</p>
          </ShareNetwork>
        </div>
        <div class="each-link">
          <ShareNetwork
            network="facebook"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <circle cx="256" cy="256" fill="#3B5998" r="256" />
                  <path
                    d="M301.6,151.2c15.6,0,36.3,0,36.3,0V97c0,0-21.8,0-51.4,0c-29.6,0-68.1,19-68.1,74.2c0,10.3,0,25.4,0,43   h-49.1v56.1h49.1c0,69.9,0,146,0,146h21.8h17.3H277c0,0,0-78.8,0-146h48.8l8.1-56.1H277c0-18.4,0-31.8,0-35.7   C277,160.1,286,151.2,301.6,151.2z"
                    fill="#FFFFFF"
                  />
                </g>
              </svg>
            </div>
            <p class="mt-1">Facebook</p>
          </ShareNetwork>
        </div>
        <div class="each-link">
          <ShareNetwork
            network="linkedin"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <circle cx="256" cy="256" fill="#0077B5" r="256" />
                  <path
                    d="M186.8,371.7h-51.9V204.1h51.9V371.7z M160.6,182.2   c-16.9,0-30.7-13.8-30.7-30.9c0-17.1,13.7-30.9,30.7-30.9c16.9,0,30.7,13.8,30.7,30.9C191.3,168.3,177.5,182.2,160.6,182.2z    M381.2,371.7h-51.6c0,0,0-63.8,0-88c0-24.1-9.2-37.6-28.2-37.6c-20.8,0-31.6,14-31.6,37.6c0,25.8,0,88,0,88h-49.8V204.1h49.8v22.6   c0,0,15-27.7,50.5-27.7c35.5,0,61,21.7,61,66.6C381.2,310.5,381.2,371.7,381.2,371.7z"
                    fill="#FFFFFF"
                    id="linkedin-square-rounded-icon_1_"
                  />
                </g>
              </svg>
            </div>
            <p class="mt-1">LinkedIn</p>
          </ShareNetwork>
        </div>
        <div class="each-link">
          <ShareNetwork
            network="twitter"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <path
                    d="M512,256c0,141.4-114.6,256-256,256C114.6,512,0,397.4,0,256C0,114.6,114.6,0,256,0   C397.4,0,512,114.6,512,256z"
                    fill="#1DA1F2"
                  />
                  <path
                    d="M358.5,202.9c3.3,73.9-51.8,156.2-149.3,156.2c-29.7,0-57.3-8.7-80.5-23.6   c27.9,3.3,55.7-4.4,77.8-21.7c-23-0.4-42.4-15.6-49.1-36.5c8.2,1.6,16.3,1.1,23.7-0.9c-25.3-5.1-42.7-27.8-42.1-52.2   c7.1,3.9,15.2,6.3,23.8,6.6c-23.4-15.6-30-46.5-16.3-70.1c25.9,31.8,64.6,52.7,108.3,54.9c-7.6-32.9,17.3-64.5,51.2-64.5   c15.1,0,28.7,6.4,38.3,16.6c12-2.4,23.2-6.7,33.4-12.7c-3.9,12.3-12.3,22.6-23.1,29.1c10.6-1.3,20.7-4.1,30.2-8.3   C377.6,186.3,368.7,195.5,358.5,202.9z"
                    fill="#FFFFFF"
                    id="twitter-4-icon_3_"
                  />
                </g>
              </svg>
            </div>
            <p class="mt-1">Twitter</p>
          </ShareNetwork>
        </div>
        <div class="each-link">
          <ShareNetwork
            network="pinterest"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                enable-background="new 0 0 512 512"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <circle cx="256" cy="256" fill="#BD081C" r="256" />
                  <path
                    d="M280.8,323.2c-18.9,0-36.6-10.2-42.6-21.8c0,0-10.1,40.2-12.3,48   c-7.6,27.4-29.8,54.9-31.5,57.1c-1.2,1.6-3.9,1.1-4.1-1c-0.5-3.5-6.1-38.1,0.5-66.3c3.3-14.2,22.5-95.1,22.5-95.1   s-5.6-11.1-5.6-27.6c0-25.9,15-45.2,33.7-45.2c15.9,0,23.5,11.9,23.5,26.2c0,16-10.2,39.8-15.4,61.9c-4.4,18.5,9.3,33.6,27.5,33.6   c33.1,0,55.3-42.5,55.3-92.8c0-38.3-25.8-66.9-72.6-66.9c-52.9,0-86,39.5-86,83.6c0,15.2,4.5,25.9,11.5,34.2   c3.2,3.8,3.7,5.4,2.5,9.7c-0.9,3.2-2.8,11-3.6,14c-1.2,4.4-4.8,6-8.8,4.4c-24.4-9.9-35.7-36.7-35.7-66.7c0-49.6,41.8-109,124.8-109   c66.7,0,110.5,48.2,110.5,100C375,272.1,336.9,323.2,280.8,323.2z"
                    fill="#FFFFFF"
                    id="pinterest-circle-icon_1_"
                  />
                </g>
              </svg>
            </div>
            <p class="mt-1">Pinterest</p>
          </ShareNetwork>
        </div>
        <!-- </div> -->
        <!--  -->
        <!-- <div
        class="second-link d-flex justify-content-center w-50 gap-2 mx-auto my-3"
      > -->
        <div class="each-link">
          <ShareNetwork
            network="email"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="SVGID_1_"
                  x1="256"
                  x2="256"
                  y1="512"
                  y2="-9.094947e-013"
                >
                  <stop offset="0" style="stop-color: #f46b45" />
                  <stop offset="1" style="stop-color: #eea849" />
                </linearGradient>
                <circle cx="256" cy="256" fill="url(#SVGID_1_)" r="256" />
                <path
                  d="M378.4,164.6H133.6c-7.8,0-14.1,6.3-14.1,14.1v154.6c0,7.8,6.3,14.1,14.1,14.1h244.7  c7.8,0,14.1-6.3,14.1-14.1V178.7C392.5,170.9,386.1,164.6,378.4,164.6z M133.6,175.2h244.7c1.9,0,3.5,1.6,3.5,3.5v0.9L256,276  l-125.9-96.4v-0.9C130.1,176.8,131.7,175.2,133.6,175.2z M378.4,336.8H133.6c-1.9,0-3.5-1.6-3.5-3.5V193l122.7,93.9  c0.2,0.2,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.7,0.3,1.3,0.5,2,0.5h0h0c0.7,0,1.4-0.2,2-0.5c0.2-0.1,0.4-0.2,0.5-0.3  c0.2-0.1,0.4-0.2,0.6-0.3L381.9,193v140.3C381.9,335.2,380.3,336.8,378.4,336.8z"
                  fill="#FFFFFF"
                />
              </svg>
            </div>
            <p class="mt-1">Email</p>
          </ShareNetwork>
        </div>
        <div class="each-link">
          <ShareNetwork
            network="tumblr"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <path
                    d="M512,256c0,141.4-114.6,256-256,256C114.6,512,0,397.4,0,256C0,114.6,114.6,0,256,0   C397.4,0,512,114.6,512,256z"
                    fill="#35465C"
                  />
                  <path
                    d="M347.8,391.5c-12.9,6.1-24.6,10.3-35.1,12.8   c-10.5,2.4-21.8,3.7-33.9,3.7c-13.8,0-26-1.7-36.6-5.2c-10.6-3.5-19.7-8.5-27.2-14.9c-7.5-6.5-12.7-13.3-15.6-20.6   c-2.9-7.3-4.3-17.8-4.3-31.6V229.7h-33.1v-43c11.8-3.9,22-9.4,30.4-16.6c8.4-7.2,15.2-15.8,20.3-25.9c5.1-10.1,8.6-22.9,10.5-38.5   h42.8v76.5h71.8v47.4h-71.8v77.5c0,17.5,0.9,28.8,2.8,33.7c1.9,5,5.3,9,10.4,11.9c6.7,4,14.3,6,22.9,6c15.3,0,30.5-5,45.7-14.9   V391.5L347.8,391.5z"
                    fill="#FFFFFF"
                    id="tumblr-square-rounded-icon_1_"
                  />
                </g>
              </svg>
            </div>
            <p class="mt-1">Tumblr</p>
          </ShareNetwork>
        </div>
        <div class="each-link">
          <ShareNetwork
            network="VK"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <circle cx="256" cy="256" fill="#45668E" r="256" />
                  <path
                    d="M212.1,192.4c1.2-3.1,3.4-5,6.2-6.4c5.3-2.6,11-3.4,16.7-3.3c8.2,0.2,16.4,0.7,24.5,1   c3.7,0.2,7.3,0.3,10.8,1.7c5.2,2.1,7.9,6,8.1,11.5c0.1,4.4-0.5,8.9-0.7,13.4c-0.3,9.5-0.7,19-0.7,28.5c0,3,1.3,6,2.1,9   c0.2,0.8,0.8,1.6,1.3,2.3c2,2.8,2.6,2.8,5.4,1c5.2-3.3,9.1-7.8,12.6-12.8c4.8-6.8,9-13.8,12.6-21.2c3.5-7.4,7.1-14.9,10.4-22.4   c1.6-3.8,4.2-5.4,8.3-5.3c15.3,0.1,30.7,0.1,46,0c4.9,0,8.4,4.2,7.3,9c-1.2,5.1-3.5,9.7-6.2,14c-7.6,12.4-16.1,24.1-25.7,35   c-3.4,3.8-6.4,7.9-8,12.9c-1.7,5.4-0.6,10.2,3.4,14.2c7.1,7.1,14.5,14,21.4,21.3c4.1,4.3,7.7,9.1,11.3,13.9c2.3,3,3.8,6.6,3.4,10.6   c-0.5,4.5-2.7,6.8-7.2,7c-8.6,0.5-17.3,0.8-26,1.2c-5,0.2-10.1,0.5-15.1,0.7c-4,0.2-7.6-1.3-11-3.3c-5.4-3.1-10-7.3-14.4-11.7   c-4.6-4.5-8.9-9.2-12.4-14.7c-2.2-3.5-8.3-5.4-12.1-3.9c-3,1.1-4.4,3.5-4.7,6.5c-0.5,5-0.8,9.9-1.2,14.9   c-0.6,6.1-4.4,9.9-10.5,10.7c-12,1.6-23.9,1-35.7-1.3c-8.7-1.7-16.2-5.9-23.3-11c-9.8-6.9-18.1-15.4-25.9-24.3   c-4-4.7-7.6-9.8-11-14.9c-11.1-16.3-21.6-32.9-30.7-50.4c-4.4-8.4-8.8-16.7-11.1-26c-0.5-2.1-0.8-4.2-1.2-6.3   c-0.2-1,0.2-1.8,1.1-2.1c2.5-0.8,5-1.9,7.5-2c8.1-0.3,16.3-0.3,24.4-0.4c3.5,0,7,0.3,10.5,0.2c4.7-0.2,7.9,1.9,9.9,5.9   c2,4,3.8,8.1,5.7,12.1c6.9,14.4,14.4,28.5,24.2,41.2c1.4,1.9,3.6,3.4,5.7,4.6c2.5,1.4,4.7,0.7,6.2-1.8c1.8-2.9,2.6-6.2,2.7-9.6   c0.1-9.6,0.4-19.2,0.3-28.8c-0.1-9.9-5-16.5-14.4-20.1C212.7,192.7,212.4,192.6,212.1,192.4z"
                    fill="#FFFFFF"
                  />
                </g>
              </svg>
            </div>
            <p class="mt-1">Vkontakte</p>
          </ShareNetwork>
        </div>
        <div class="each-link">
          <ShareNetwork
            network="reddit"
            :url="base + img"
            title="Trucks"
            hashtags="trucks"
          >
            <div>
              <svg
                version="1.1"
                viewBox="0 0 512 512"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <path
                    d="M512,256c0,141.4-114.6,256-256,256C114.6,512,0,397.4,0,256C0,114.6,114.6,0,256,0   C397.4,0,512,114.6,512,256z"
                    fill="#FF4500"
                  />
                  <g>
                    <g>
                      <circle cx="206.6" cy="271.9" fill="#FFFFFF" r="23.9" />
                      <circle cx="307.9" cy="271.9" fill="#FFFFFF" r="23.9" />
                    </g>
                    <g>
                      <path
                        d="M427.9,244.5c0-21.6-17.6-39.1-39.1-39.1c-9.6,0-18.9,3.6-26,9.9c-26.5-17.1-60.7-27.1-97.2-28.4     l23.3-64.6l54.9,13.3c0,0.5-0.1,0.9-0.1,1.4c0,17.5,14.3,31.8,31.8,31.8s31.8-14.3,31.8-31.8c0-17.5-14.3-31.8-31.8-31.8     c-12.8,0-23.8,7.6-28.8,18.5l-65.7-16l-28.5,79.2c-37.8,0.8-73.3,10.8-100.6,28.5c-7.1-6.3-16.4-9.9-26-9.9     c-21.6,0-39.1,17.6-39.1,39.1c0,14,7.3,26.6,19.2,33.7c-0.6,3.8-1,7.7-1,11.7c0,28,16.2,54.2,45.6,73.7     c28.6,19,66.6,29.4,106.9,29.4c40.3,0,78.2-10.4,106.8-29.4c29.4-19.5,45.6-45.6,45.6-73.7c0-3.9-0.3-7.8-1-11.7     C420.5,271.1,427.9,258.4,427.9,244.5z M375.4,117.5c10.7,0,19.5,8.7,19.5,19.5c0,10.7-8.7,19.5-19.5,19.5     c-10.7,0-19.5-8.7-19.5-19.5C355.9,126.2,364.7,117.5,375.4,117.5z M98.9,244.5c0-14.8,12-26.8,26.8-26.8c5.7,0,11.1,1.8,15.6,5     c-15.8,12.4-26.7,27-32.3,42.7C102.7,260.4,98.9,252.8,98.9,244.5z M257.2,380.5c-77.3,0-140.1-40.7-140.1-90.7     c0-50,62.9-90.7,140.1-90.7s140.1,40.7,140.1,90.7C397.3,339.8,334.5,380.5,257.2,380.5z M405.5,265.4     c-5.6-15.7-16.5-30.3-32.3-42.7c4.5-3.2,10-5,15.6-5c14.8,0,26.8,12,26.8,26.8C415.5,252.7,411.7,260.4,405.5,265.4z"
                        fill="#FFFFFF"
                      />
                      <path
                        d="M306,326.9c-10.7,9.5-28.9,15.2-48.8,15.2s-38.1-5.7-48.8-15.2c-2.5-2.3-6.4-2.1-8.7,0.5     c-2.3,2.5-2.1,6.4,0.5,8.7c12.9,11.5,34.2,18.4,57,18.4s44.2-6.9,57-18.4c2.5-2.3,2.8-6.2,0.5-8.7     C312.5,324.8,308.6,324.6,306,326.9z"
                        fill="#FFFFFF"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <p class="mt-1">Reddit</p>
          </ShareNetwork>
        </div>
        <div class="each-link p-2">
          <div @click="$emit('printEmit')">
            <svg
              version="1.1"
              viewBox="0 0 500 499.988"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g id="printer">
                <g>
                  <path
                    d="M250.001,0C111.93,0,0,111.925,0,249.992c0,138.071,111.93,249.996,250.001,249.996    C388.07,499.988,500,388.063,500,249.992C500,111.925,388.07,0,250.001,0z"
                    style="fill: #656d78"
                  />
                  <g id="_x31_0">
                    <g>
                      <g>
                        <path
                          d="M323.909,289.444c0,7.019-5.668,12.699-12.685,12.699h-123.46       c-7.017,0-12.695-5.68-12.695-12.699V138.513c0-7.014,5.678-12.704,12.695-12.704h123.46c7.017,0,12.685,5.69,12.685,12.704       V289.444z"
                          style="fill: #ffffff"
                        />
                        <path
                          d="M187.764,129.592c-4.914,0-8.921,4.002-8.921,8.921v150.931c0,4.919,4.007,8.921,8.921,8.921       h123.46c4.899,0,8.906-4.002,8.906-8.921V138.513c0-4.919-4.007-8.921-8.906-8.921H187.764L187.764,129.592z"
                          style="fill: #ffffff"
                        />
                      </g>
                      <g>
                        <rect
                          height="5.341"
                          style="fill: #e0e2e4"
                          width="120.4"
                          x="189.801"
                          y="150.723"
                        />
                        <rect
                          height="5.336"
                          style="fill: #e0e2e4"
                          width="120.4"
                          x="189.801"
                          y="170.311"
                        />
                        <rect
                          height="5.34"
                          style="fill: #e0e2e4"
                          width="120.4"
                          x="189.801"
                          y="189.894"
                        />
                        <rect
                          height="5.336"
                          style="fill: #e0e2e4"
                          width="120.4"
                          x="189.801"
                          y="209.482"
                        />
                        <rect
                          height="5.346"
                          style="fill: #e0e2e4"
                          width="120.4"
                          x="189.801"
                          y="229.065"
                        />
                        <rect
                          height="5.336"
                          style="fill: #e0e2e4"
                          width="120.4"
                          x="189.801"
                          y="248.648"
                        />
                      </g>
                    </g>
                    <path
                      d="M183.463,221.342c-5.195,0-9.449,1.421-9.449,3.178v53.767c0,1.751,4.254,3.177,9.449,3.177     h130.848c5.198,0,9.442-1.426,9.442-3.177V224.52c0-1.756-4.245-3.178-9.442-3.178H183.463L183.463,221.342z"
                      style="fill: #e0e2e4"
                    />
                    <g>
                      <path
                        d="M346.18,354.495H153.812c-3.857,0-6.976,3.129-6.976,6.976c0,3.856,3.119,6.98,6.976,6.98h6.527      c-0.01,0.17-0.049,0.325-0.049,0.495c0,2.891,2.348,5.229,5.234,5.229h42.846c2.896,0,5.232-2.338,5.232-5.229      c0-0.17-0.034-0.325-0.044-0.495h72.866c-0.01,0.17-0.041,0.325-0.041,0.495c0,2.891,2.333,5.229,5.229,5.229h42.846      c2.91,0,5.239-2.338,5.239-5.229c0-0.17-0.034-0.325-0.046-0.495h6.53c3.849,0,6.98-3.124,6.98-6.98      C353.16,357.624,350.029,354.495,346.18,354.495z"
                        style="fill: #848a93"
                      />
                      <path
                        d="M351.547,246.77H148.44c-17.236,0-31.231,13.665-31.231,30.522v40.714      c0,16.857,13.995,30.541,31.231,30.541h203.107c17.25,0,31.245-13.685,31.245-30.541v-40.714      C382.792,260.435,368.797,246.77,351.547,246.77z"
                        style="fill: #ffffff"
                      />
                      <g>
                        <path
                          d="M353.17,297.152c0,4.25-3.454,7.694-7.696,7.694c-4.255,0-7.696-3.444-7.696-7.694       c0-4.249,3.441-7.698,7.696-7.698C349.716,289.453,353.17,292.903,353.17,297.152z"
                          style="fill: #7dbebd"
                        />
                      </g>
                      <g>
                        <path
                          d="M319.565,297.142c0,4.259-3.442,7.713-7.706,7.713c-4.24,0-7.686-3.454-7.686-7.713       c0-4.245,3.447-7.698,7.686-7.698C316.124,289.444,319.565,292.898,319.565,297.142z"
                          style="fill: #f2955c"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Layer_1" />
            </svg>
          </div>
          <p class="mt-1 text-light">Print</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "img", "base"],
  emits: ["closeLinks", "printEmit"],
  data() {
    return {
      search: "",
    };
  },
};
</script>

<style scoped>
.each-link {
  cursor: pointer;
}
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #fff;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  color: #818181;
}
p {
  font-size: 0.9rem;
}
.each-link {
  width: 17%;
}
.each-link svg {
  width: 64px;
  transition: all 0.1s ease-in-out;
}
.each-link svg:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 992px) {
  .links-container {
    width: 80% !important;
  }
}
@media screen and (max-width: 578px) {
  .links-container {
    width: 100% !important;
  }
  .each-link svg {
    width: 48px;
    height: 48px;
  }
  .each-link p {
    font-size: 0.8rem;
  }
  .each-link:last-child {
    padding: 0.9rem 0.5rem !important;
  }
}
</style>
